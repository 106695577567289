@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/eot/iranyekanwebboldfanum.eot");
  src: url("../fonts/eot/iranyekanwebboldfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebboldfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebboldfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/eot/iranyekanwebthinfanum.eot");
  src: url("../fonts/eot/iranyekanwebthinfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebthinfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebthinfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/eot/iranyekanweblightfanum.eot");
  src: url("../fonts/eot/iranyekanweblightfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanweblightfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanweblightfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/eot/iranyekanwebregularfanum.eot");
  src: url("../fonts/eot/iranyekanwebregularfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebregularfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebregularfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/eot/iranyekanwebmediumfanum.eot");
  src: url("../fonts/eot/iranyekanwebmediumfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebmediumfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebmediumfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/eot/iranyekanwebextraboldfanum.eot");
  src: url("../fonts/eot/iranyekanwebextraboldfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebextraboldfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebextraboldfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/eot/iranyekanwebblackfanum.eot");
  src: url("../fonts/eot/iranyekanwebblackfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebblackfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebblackfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 950;
  src: url("../fonts/eot/iranyekanwebextrablackfanum.eot");
  src: url("../fonts/eot/iranyekanwebextrablackfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebextrablackfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebextrablackfanum.ttf") format("truetype");
}

* {
  -webkit-font-smoothing: antialiased;
}

body {
  direction: rtl;
  text-align: right;
  background: #f4f4f9;
}

.app-cover {
  height: 450px;
  width: 100%;
  background: url(../images/bg.jpg) no-repeat center;
  background-size: cover;
  z-index: -1;
  position: absolute;
}

.app-hero {
  h1 {
    font-size: 37px;
    font-weight: bold;
  }

  p {
    font-size: 17px;
    opacity: 0.56;
    margin-top: 20px;
    max-width: 620px;
    line-height: 34px;
  }
}

.app-logo {
  height: 47px;
}

.app-block {
  background: #ffffff;
  box-shadow: 0 20px 40px 0 rgba(36, 36, 55, 0.05);
  border-radius: 10px;
}

.app-main {
  input[type="text"] {
    height: 55px;
    font-size: 16px;
  }
  .btn-purple {
    background-image: linear-gradient(124deg, #6146c0 0%, #9059c7 100%);
    border-radius: 5px;
    border: none;
  }
  h2 {
    font-size: 16px;
    color: #484c6d;
  }
}

.app-pagination {
  list-style: none;
  li {
    background: #f4f4f9;
    cursor: pointer;
    font-size: 15px;
    color: #484c6d;
    &.active {
      background-image: linear-gradient(124deg, #6146c0 0%, #9059c7 100%);
      color: white;
    }
  }
}

.app-streamer-grid {
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background: #f0f1f5;
    border: none;
    transition: 0.5s;
  }
}

.followers-list {
  img {
    min-width: 55px;
    width: 55px;
    height: 55px;
  }
  .streamer-name {
    font-size: 16px;
    color: #22264b;
    letter-spacing: 0;
    text-align: right;
    font-weight: bold;
  }

  .followers-count {
    font-size: 13px;
    color: rgba(72, 76, 109, 0.69);
    letter-spacing: 0;
    text-align: right;
  }
}

.checkout-container {
  .price-container {
    color: #383b5b;
  }
  .invoice-container {
    background: #f4f4f9;
    color: #484c6d;
  }
}
.qa-body {
  color: #383b5b;
  line-height: 30px;
  .qa-question {
    font-weight: bold;
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      background: #b474de;
      border-radius: 50%;
      position: relative;
      display: inline-flex;
      top: 1px;
      margin-left: 5px;
    }
  }
  .qa-answer {
    opacity: 0.8;
    font-size: 15px;
    pre {
      background: #ededf5;
      border-radius: 0.25rem;
      display: inline-flex;
    }
  }
}

.selected-streamer {
  max-width: 300px;
  background: #f4f4f9;
  .streamer-name {
    color: #383b5b;
    font-family: "Arial", sans-serif;
  }
  .streamer-followers {
    color: #383b5b;
    opacity: 0.5;
  }
  img {
    width: 55px;
    height: 55px;
  }
}

.search-suggestion-dropdown {
  z-index: 10;
  top: 50px;
  background: #f2f3f9;
  width: calc(100% + 4px);
  margin-right: -2px;
  // border-top: 1px solid rgba(0, 0, 0, 0.05);

  span {
    font-size: 17px;
  }
}

.search-icon {
  position: absolute;
  right: 20px;
  top: 18px;
}

.search-result {
  transition: 0.3s;
  cursor: pointer;
  background: white;
  box-shadow: 0 30px 40px rgba(48, 55, 68, 0.09);
  font-family: Arial;

  &:hover {
    background: #f4f4f9;
    transition: 0.3s;
    cursor: pointer;
  }
}

.app-footer {
  color: #535565;
}

.app-notification {
  background: #eee8ff;
  color: #b76aef;
  font-size: 15px;
  line-height: 32px;
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 20;
}

.already-selected-input {
  &:focus {
    box-shadow: none !important;
    background: #f4f3f6;
  }
}

.selected-badge {
  position: absolute;
  margin-top: 15px;
  margin-right: 20px;
  a {
    cursor: pointer;
  }
  span {
    border-radius: 35px;
  }
}

.streamer-name {
  font-family: "Arial", sans-serif;
}

.emote-container {
  background: #f0e4f7;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ab77de;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.app-streamer-header {
  background: #f8f9fc;
  position: relative;
  min-height: 105px;
  border-radius: 10px 10px 0 0;

  img {
    width: 50px;
    height: 50px;
  }
  h2 {
    color: #34383e;
    font-size: 18px;
  }
  span {
    color: #acb1b9;
    font-size: 14px;
    font-weight: 600;
  }
  .app-streamer-header-banner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 10px 10px 0 0;
  }
}

.app-tiers {
  .app-tier {
    padding-bottom: 30px;
    margin-bottom: 35px;
    border-bottom: 1px solid #f3f3f3;

    &#tier-2 {
      h2 {
        span {
          background-image: linear-gradient(90deg, #e67b50 0%, #ffd68d 100%);
        }
      }
    }

    &#tier-3 {
      h2 {
        span {
          background-image: linear-gradient(-270deg, #ff5050 0%, #dda7ff 100%);
        }
      }
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    h2 {
      font-size: 17px;
      font-weight: bold;
      color: #34373d;
      span {
        background-image: linear-gradient(270deg, #ab6bd0 0%, #3c258f 100%);
        border-radius: 14px;
        font-size: 15px;
        color: white;
        font-weight: bold;
        min-height: 30px;
        width: 0;
        min-width: 75px;
        padding-top: 2px;
      }
    }
    ul {
      color: #eaeaf0;

      li {
        display: flex;
        align-items: center;

        &:before {
          content: "";
          background: #dfdfe6;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          display: inline-block;
          margin-left: 8px;
        }

        color: #5b636e;
        font-size: 14px;
        line-height: 35px;
      }
    }
    .app-tier-emotes-list {
      border: 1px solid #f0f0f2;
      border-radius: 4px;
      line-height: 40px;
      min-width: 220px;
      .app-tier-emote {
        cursor: pointer;
      }
    }

    .app-tier-purchase {
      background: #f8f9fc;
      border-radius: 7px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .app-tier-back {
    color: #858799;
    font-size: 13px;
  }
}

.ant-tooltip-inner {
  border-radius: 5px;
}

.alert-danger {
  &:before {
    width: 28px;
    height: 28px;
    background: url(https://static-cdn.jtvnw.net/emoticons/v1/86/3.0) no-repeat;
    background-size: 28px;
    display: inline-block;
    vertical-align: middle;
    content: "";
    margin-left: 10px;
    margin-top: 5px;
  }
  color: #e04857;
  background-color: #fff6f7;
  border-color: #ffcfd4;
  border-radius: 6px !important;
}

.alert-success {
  &:not(.no-image) {
    &:before {
      width: 28px;
      height: 28px;
      background: url(https://static-cdn.jtvnw.net/emoticons/v1/305954156/2.0)
        no-repeat;
      background-size: auto 28px;
      display: inline-block;
      vertical-align: middle;
      content: "";
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  border-radius: 6px !important;
}

.alert-info {
  &:not(.no-image) {
    &:before {
      width: 28px;
      height: 28px;
      background: url(https://cdn.frankerfacez.com/emote/557676/2) no-repeat;
      background-size: auto 28px;
      display: inline-block;
      vertical-align: middle;
      content: "";
      margin-left: 10px;
      margin-top: 5px;
      border-radius: 50%;
    }
  }
  border-radius: 6px !important;
}

.app-tier-checkout {
  background: #f2f3f9;
  border-radius: 6px;
  min-width: 300px;
  .app-tier-checkout-row {
    border-bottom: 1px solid #e3e3ee;
    padding-bottom: 8px;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.app-tier-payment-form {
  label {
    color: #484c6d;
    font-size: 15px;
    line-height: 29px;
  }
}

.form-control {
  background-color: #fff;
  border-color: #e6dffb;
  box-shadow: 0 0 0 2px #eeeef4;
}

.form-control:focus,
.form-control-focus {
  color: #495057;
  background-color: #fff;
  border-color: #e6dffb;
  outline: 0;
  box-shadow: 0 0 0 2px #f2f3f9;
}

.form-control-focus {
  border-radius: 0.25rem 0.25rem 0 0;
}

.form-control.selected {
  background: transparent;
  box-shadow: 0 1px 2px 0 rgba(244, 247, 253, 0.5);
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  padding-right: 0;
}

.ant-notification-notice-closable {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.7);
}

.form-check-inline {
  display: inline-block;
  margin-right: unset;
  margin-left: 1rem;
}

.form-check .form-check-input {
  float: right;
  margin-left: unset;
  margin-right: -1.75em;
}

.form-check {
  padding-left: unset;
  padding-right: 1.75em;
}

.form-check-input:checked {
  background-color: #8e6ff0;
  border-color: #8e6ff0;
}

.form-check-input:focus {
  border-color: #c6b9f2;
  box-shadow: 0 0 0 0.2rem rgb(232, 225, 255);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.rounded-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #ae56f9;
}

.ant-pagination-item-active:focus {
  border-color: #9238de;
}

.ant-pagination-item-active:hover {
  border-color: #cb8eff;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #c47eff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar-nav li:last-child {
  padding-left: 0 !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ant-table-thead > tr > th {
  border-bottom: 0;
}
