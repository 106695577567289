$enable-negative-margins: true;

//  *** RESPONSIVE BREAK POINTS ***
$grid-breakpoints: (
        xxs: 0px,
        xs: 375px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

//  *** CONTAINER WIDTHS ***
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1340px
);

$px-spacer: 10px;

$px-spacers: map-merge(
                (
                        0: 0,
                        half: $px-spacer / 2,
                        10: $px-spacer * 1,
                        15: $px-spacer * 1.5,
                        20: $px-spacer * 2,
                        25: $px-spacer * 2.5,
                        30: $px-spacer * 3,
                        35: $px-spacer * 3.5,
                        40: $px-spacer * 4,
                        45: $px-spacer * 4.5,
                        50: $px-spacer * 5,
                        55: $px-spacer * 5.5,
                        60: $px-spacer * 6,
                        65: $px-spacer * 6.5,
                        70: $px-spacer * 7,
                        80: $px-spacer * 8,
                        90: $px-spacer * 9,
                        100:$px-spacer * 10,
                        120:$px-spacer * 12,
                        130:$px-spacer * 13,
                        150:$px-spacer * 15

                ),
                $spacers
);

$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $px-spacers
);

$theme-colors: map-merge($theme-colors, (
        "dark-purple": #44446C,
        "green": #42CB83,
        "success": #42CB83,
        "light": #F2F4F9,
        "gray": #808393,
        "purple": #6165ff,
        "light-gray": #DFE2EB,
        "white": #fff
));

$input-border-color: #eaeaf9;
$input-placeholder-color: #7D8CA4;
$input-border-width: 0px;
$input-bg: #F2F3F9;
$input-padding-y: 0.85rem;
$small-font-size: 85%;
$input-font-size: 15px;
$font-family-base: 'iranyekan', sans-serif;
$alert-padding-y: .40rem;
$alert-padding-x: 1.4375rem;
$alert-border-radius: 10rem;
$link-color: #a361da;
$link-hover-color: darken($link-color, 10%);
$btn-padding-y: .75rem;
$btn-padding-x: 1.55rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2.9rem;

.alert {
  font-size: 15px;
}

.btn {
  font-weight: 500 !important;
  font-size: 15px !important;
}